import React,{useEffect,useState,useRef} from 'react'
import { motion } from "framer-motion";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function TataTiagoEast() {
  
  /*
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const aff_id = searchParams.get('aff_id');*/
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utm_source = searchParams.get('utm_source');
  const [formData, setFormData] = useState({
    name:'',
    email:'',
    mobile:'',
    state:'',
    city:'',
    model:'Tiagoev',
    utm_source: '',
    utm_campaign:'Tiago_EV'
   
  })
  const handleTermsChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      utm_source: utm_source
    }));
  }, [utm_source]);
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({...formData,[e.target.name]:e.target.value});
  }
  const validateForm = () => {
    let formIsValid = true;
    let tempErrors = {};

    // Validate name
    if (!formData.name) {
      tempErrors.name = 'Name is required';
      formIsValid = false;
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailPattern.test(formData.email)) {
      tempErrors.email = 'Invalid email address';
      formIsValid = false;
    }

    // Validate mobile number (should be 10 digits)
    const mobilePattern = /^[6-9]\d{9}$/;
    if (!formData.mobile || !mobilePattern.test(formData.mobile)) {
      tempErrors.mobile = 'Invalid mobile number (should be 10 digits and start with 6-9)';
      formIsValid = false;
    }

    // Validate city and state
    if (!formData.city) {
      tempErrors.city = 'City is required';
      formIsValid = false;
    }
    if (!formData.state) {
      tempErrors.state = 'State is required';
      formIsValid = false;
    }

    // Check if terms are accepted
    if (!isTermsAccepted) {
      tempErrors.terms = 'You must accept the terms and conditions';
      formIsValid = false;
    }

    setErrors(tempErrors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      axios
        .post('https://api.tataevcars.info/submitLead', formData)
        .then((response) => {
          if (response.data.status === true) {
            toast.success('Thank you for contacting us!');
          } else if (response.data.status === false) {
            toast.error('You have already enquired. We will contact you ASAP!');
          }
        })
        .catch((err) => {
          toast.error('Something went wrong. Please try again later.');
          console.log(err);
        });
    } else {
      toast.error('Please correct the highlighted fields.');
    }
  };

  const settings = {
    dots: true,  // Enables the dots below the slider
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1
  };

  const slidesData = [
    
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/ev-front-grille-new (1).jpg`,
      heading: '.ev Front Grille',
      content: 'You get the ev signature look upfront.'
    }
    ,
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/DRL-1-1.jpg`,
      heading: 'Sequential Front Side Indicators',
      content: 'Showcase sophistication with Sequential Side Indicators, creating a dynamic visual effect.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/projector-headlamps-new.jpg`,
      heading: 'Projector headlamps',
      content: 'Confidently navigate the road ahead with better visibility.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/hyperstyle-wheels-new.jpg`,
      heading: 'Hyperstyle Wheels',
      content: 'Stand out from the crowd with R14 Hyperstyle Wheels.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/PremiumLeatheretteseatupholstery-1.jpg`,
      heading: 'Plush Leatherette upholstery',
      content: 'So you can sit back and relax.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/flat-bottom-steering-wheel-new.jpg`,
      heading: 'Flat Bottom Steering Wheel',
      content: 'You get a smooth grip for smoother drives. '
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/touchscreen-infotainment-system-by-harman-new.jpg`,
      heading: '17.78 cm (7") Touchscreen',
      content: '17.78 cm (7") Touchscreen Infotainment system by Harman. So every drive is immersive for you.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/4Speakers-4Tweeters-2.jpg`,
      heading: '8 speaker system',
      content: 'Sound like a fun drive.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/front-usb-c-type-charger-new-1.jpg`,
      heading: 'Front USB C Type Charger (45W)',
      content: 'Fast charge your gadgets on-the-go.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/rear-wiper-washer-with-demister.jfif`,
      heading: 'Rear Wiper Washer & Demister',
      content: 'For clear rear visibility during rains or in winter season.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/tiago/electric-orvm.jpg`,
      heading: 'Electric ORVM with Auto Fold',
      content: 'Convenience at the touch of a button for the perfect rear view.'
    }
   
  ];
  return (
    <>
    <ToastContainer
position="top-left"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
        <motion.section  id="section1">
        <div className='container-fluid p-0 m-0  d-flex align-items-center' id="tiago_east">
            <div className='row' style={{width:"100%"}}>
            <div className='col-md-8'></div>
            <div className='col-md-4 ps-5'>
                
                  <form id='TataTiagoForm'>
                  <h2 className='headh1  text-white mb-3'>Tiago.ev</h2>
                  <div className='mb-3'>
                      <input type="text" name='name' value={formData.name} onChange={handleChange} className="form-control" placeholder="Enter Your Name" />
                      {errors.name && <span className="text-white">{errors.name}</span>}
                    </div>
                    <div className='mb-3'>
                      <input type="email" name='email' value={formData.email} onChange={handleChange} className="form-control" placeholder="Enter Your Email" />
                      {errors.email && <span className="text-white">{errors.email}</span>}
                    </div>
                    <div className='mb-3'>
                      <input type="text" name='mobile' value={formData.mobile} onChange={handleChange} className="form-control" placeholder="Enter Your Mobile Number" />
                      {errors.mobile && <span className="text-white">{errors.mobile}</span>}
                    </div>
                    <div className='row'>
                    <div className='mb-3 col-12 col-md-12'>
                      <input type="text" name='city' value={formData.city} onChange={handleChange} className="form-control" placeholder="Enter City" />
                      {errors.city && <span className="text-white">{errors.city}</span>}
                    </div>
                    <div className='mb-3 col-12 col-md-12'>
                      <input type="text" name='state' value={formData.state} onChange={handleChange} className="form-control" placeholder="Enter State" />
                      {errors.state && <span className="text-white">{errors.state}</span>}
                    </div>
                    </div>
                    <input type='hidden' name='model' value={formData.model} />
                    <div className="mb-3 input-wrapper">
                    <input
                      type="checkbox"
                      id="terms"
                      onChange={handleTermsChange}
                      checked={isTermsAccepted}
                    />
                    <label htmlFor="terms " className='text-white'>
                      &nbsp;I Accept <a href="#" className='text-white' target="_blank">Terms and Conditions</a> <span className="text-white">*</span>
                    </label><br/>
                    {errors.terms && <span className="text-white">{errors.terms}</span>}
                  </div>
                    <div className='mb-3 text-center'>
                      <button type="button" className='btn btn-default' onClick={handleSubmit} >Submit Enquiry</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        </motion.section>

        <motion.section  className='bg-dark py-3 d-flex align-items-center' id="section2">
          <div className='container'>
            <div className='row text-center'>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Range</h2>
              <h5 className='text-white fw-light'>275# Km</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Fast Charge</h2>
              <h5 className='text-white fw-light'>58 min*</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>0 to 60kmph</h2>
              <h5 className='text-white fw-light'>5.7^ sec</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Safety</h2>
              <h5 className='text-white fw-light'>4 star rated safety</h5>
            </div>
            </div>
          </div>
        </motion.section>
        <motion.section  className='py-3 d-flex align-items-center' id="section3">
          <div className='container'>
            <div className='row'>
            <div className='col-12 mb-3 text-center'>
            <h2 className='section2-card-head'>Tiago.evFeatures</h2>
            </div>
            <Slider {...settings}>
              {slidesData.map((slide, index) => (
                <div key={index} className="slide">
                  <div className="slide-content">
                    <div className="image">
                      <img src={slide.image} alt={`Slide ${index + 1}`} style={{borderRadius:"4px"}} className='img-fluid'/>
                    </div>
                    <div className="text-content ps-3">
                      <h2>{slide.heading}</h2>
                      <p>{slide.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            </div>
          </div>
        </motion.section>
        <motion.section className="d-flex align-items-center" id="section4" style={{backgroundColor:"#000 !important"}}>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12">
              <iframe
                className="w-100"
                height="500" // You can adjust the height as needed
                src="https://www.youtube.com/embed/iVUfpYWDhoU?autoplay=1&mute=1&controls=0&loop=1&playlist=iVUfpYWDhoU"
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              </div>
            </div>
          </div>
        </motion.section>

       
        


        <motion.section class="d-flex align-items-center" id="section5">
          <div class="container pb-5 ">
            <div class="row">
              <div className='col-12 text-center'>
              <h2 className='section2-card-head'>Tiago.ev Colors</h2>
              </div>
              <div class="col-6 col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/tiago/colors/PrestineWhite-0.jfif`} className='img-fluid' />
                <h6>Pristine White</h6>
              </div>
              <div class="col-6 col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/tiago/colors/SignatureTealBlue-0.jfif`} className='img-fluid' />
                <h6>Signature Teal Blue</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/tiago/colors/TropicalMist-0.jfif`} className='img-fluid' />
                <h6>Tropical Mist</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/tiago/colors/MidnightPlum-0.jfif`} className='img-fluid' />
                <h6>Midnight Plum</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/tiago/colors/DaytonaGrey-0.jfif`} className='img-fluid' />
                <h6>Daytona Grey</h6>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section className="bg-dark py-5 d-flex align-items-center" id="section6">
          <div className="container p-0">
            <div className="row text-center">
            <div className='col-12'><h4 className='section2-card-head text-white'>Tiago.ev Safe Side</h4></div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/tiago/dual-front-airbag-new.jfif`} className='img-fluid' />
             <h6 className=' text-white'>Dual Front Airbags</h6>
             </div>
             </div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/tiago/abs-with-ebd-new.jfif`} className='img-fluid' />
             <h6 className=' text-white'>ABS With EBD</h6>
             </div>
             </div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/tiago/front-fog-lamps-new.jfif`} className='img-fluid' />
             <h6 className=' text-white'>Front Fog Lamps With DRLs</h6>
             </div>
             </div>
            </div>
          </div>
        </motion.section>

        <motion.section className=" d-flex align-items-center" id="section6">
          <div className="container p-0">
          <div className='row'>
          <div className='col-12 text-center pb-5'><h4 className='section2-card-head '>Schedule A Test Drive</h4></div>
          </div>
            <div className="row text-center  d-flex align-items-center">
           
             <div className='col-12 col-md-6'>
             <img src={`${process.env.PUBLIC_URL}/images/tiago/Book-a-test-drive-tile-New.jfif`} className='img-fluid' style={{borderRadius:"4px"}}/>
             
             </div>
             <div className='col-12 col-md-6'>
             
             <h4 className='fw-light'>We know you will love our EVs, which is why you can book a test drive online at the nearest dealership.</h4>
             
             </div>
             
            </div>
          </div>
        </motion.section>




    </>
  )
}

export default TataTiagoEast