import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import './App.css';
import TataNexon from './pages/TataNexon';
import TataPunch from './pages/TataPunch';
import TataTiago from './pages/TataTiago';
import TataCurvv from './pages/TataCurvv';
import Home from './pages/Home';
import TataTiagoSouth from './pages/TataTiagoSouth';
import TataTiagoRoi from './pages/TataTiagoRoi';
import TataTiagoEast from './pages/TataTiagoEast';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Nexon_Ev" element={<TataNexon />} />
        <Route exact path="/Punch_Ev" element={<TataPunch />} />
        <Route exact path="/Tiago_Ev" element={<TataTiago />} />
        <Route exact path="/Curvv_Ev" element={<TataCurvv/>} />
        <Route exact path="/Tiago_Ev_West" element={<TataTiagoSouth/>} />
        <Route exact path="/Tiago_Ev_Roi" element={<TataTiagoRoi/>} />
        <Route exact path="/Tiago_Ev_East" element={<TataTiagoEast/>} />
      </Routes>
      
    </Router>
  );
}

export default App;
